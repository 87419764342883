import React from "react";
import "../assets/AdminNavbar.css";
import Logo from "../assets/img/logo (4).png";

const Navbar = () => {
  return (

    <nav className="navbar">
      <img
        className="img__logo"
        src={Logo}
        alt=""
      />
    </nav>
  );
};

export default Navbar;
