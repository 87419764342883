import React, { useContext, useEffect } from "react";
import "./App.css";
import Phonelogin from "./components/Phonelogin";
import { AuthContext } from "./context/AuthContext";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from './Admin Panel/AdminDashboard';
import StudentDashboard from "./components/StudentDashboard";
import Profile from "./components/Profile";
import FileUpload from "./Admin Panel/FolderUpload";
import UnregisterUsers from "./Admin Panel/UnregisterUsers";
import Students from "./Admin Panel/Students";
import StudentRequest from "./Admin Panel/StudentRequest";
import RejectedStudents from "./Admin Panel/RejectedStudents";
import MultiUserDataUpload from "./Admin Panel/ExcelData";
import Register from "./components/Register";
import BatchMateData from "./components/BatchMateData";
import FullData from "./Admin Panel/FullData";
import FullExcelData from "./Admin Panel/FullExcelData";
import EditDetails from "./components/EditDetails";
import AdminList from "./Admin Panel/AdminList";

const App = () => {
  const { currentUser } = useContext(AuthContext);

  // const disableRightClick = (event) => {
  //   event.preventDefault();
  // };

  // const disableDeveloperTools = (event) => {
  //   if (event.ctrlKey && event.shiftKey && event.key === 'I') {
  //     event.preventDefault();
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('contextmenu', disableRightClick);
  //   document.addEventListener('keydown', disableDeveloperTools);

  //   return () => {
  //     document.removeEventListener('contextmenu', disableRightClick);
  //     document.removeEventListener('keydown', disableDeveloperTools);
  //   };
  // }, []);

  return (
    // <BrowserRouter basename="/mps-alumni.com/build">
    <BrowserRouter>
      <ToastContainer />
      <ConditionalNavigation currentUser={currentUser} />
      <Routes>
        <Route path="/" element={<Phonelogin />} />
      </Routes>

      <Routes>
        <Route path="/register" element={<Register />} />
      </Routes>

      <Routes>
        <Route path="/edit" element={<EditDetails />} />
      </Routes>

        <Routes>
          <Route path="/profile" exact element={<Profile />} />
        </Routes>

      <Routes>
          <Route path="/admin" exact element={<AdminDashboard />} />
        </Routes>

        <Routes>
          <Route path="/request" exact element={<StudentRequest />} />
        </Routes>

        <Routes>
          <Route path="/Dashboard" exact element={<StudentDashboard />} />
        </Routes>

        <Routes>
          <Route path="/batchMate" exact element={<BatchMateData />} />
        </Routes>

        <Routes>
          <Route path="/upload" exact element={<FileUpload />} />
        </Routes>

        <Routes>
          <Route path="/uploadExcel" exact element={<MultiUserDataUpload />} />
        </Routes>

        <Routes>
          <Route path="/registered" exact element={<UnregisterUsers />} />
        </Routes>

        <Routes>
          <Route path="/students" exact element={<Students />} />
        </Routes>

        <Routes>
          <Route path="/fulldata" exact element={<FullData />} />
        </Routes>

        <Routes>
          <Route path="/fullexcel" exact element={<FullExcelData />} />
        </Routes>

        <Routes>
          <Route path="/rejected" exact element={<RejectedStudents />} />
        </Routes>

        <Routes>
          <Route path="/adminList" exact element={<AdminList />} />
        </Routes>

    </BrowserRouter>
  );
};

const ConditionalNavigation = ({ currentUser }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  return null;
};

export default App;


