import React from "react";
import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import OtpInput from "otp-input-react";
import { useState, useContext } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../Firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import "../assets/phonelogin.css";
import { AuthContext } from "../context/AuthContext";
import { get, ref, set, serverTimestamp } from "firebase/database";
import { database } from "../Firebase";
import { onValue } from "firebase/database";
import Logo from "../assets/img/logo (4).png";

const Phonelogin = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [countryCode, setCountryCode] = useState('na');
  const [deletedStatus, setDeletedStatus] = useState();

  const handleOnChange = (value, country) => {
    setCountryCode(country.dialCode);
    setPh(value)
  };
  // localStorage.setItem(countryCode, "countryCode")
  const { dispatch } = useContext(AuthContext);


  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + ph;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sent successfully!");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        setUser(res.user);
        setLoading(false);
        dispatch({ type: "LOGIN", payload: res.user });
        const dealersRef = ref(database, `Users/${res.user.uid}`);
        onValue(dealersRef, async (snapshot) => {
       
          if (snapshot.exists()) {
            const status = snapshot.val().status;

            if (snapshot.val().role === "Admin") {
              navigate("/admin");
              localStorage.setItem("uid",res.user.uid);
              toast.success("Login Successfully");
            }
            else if(status === "Deleted"){
              navigate("/");
              setDeletedStatus(status);
            }
             else {
              if(snapshot.val().email !== undefined){
                navigate("/Dashboard");
                localStorage.setItem("uid",res.user.uid);
                toast.success("Login Successfully");
              }
              else{
                navigate("/register",{ state: { countryCode: countryCode } });
                localStorage.setItem("uid",res.user.uid);
                toast("Please Register here");
              }
            }
          } else {
            await set(ref(database, `Users/${res.user.uid}`), {
              id: res.user.uid,
              mobile_no: res.user.phoneNumber,
              role: "Student",
              status: true,
              private: {
                a: "b"
              },
            });
            navigate("/register", { state: { countryCode: countryCode } });
            localStorage.setItem("uid",res.user.uid);
          }
        });
      })

      .catch((err) => {
        console.log(err);
        toast("Try after some time");
        setLoading(false);
      });
  }

  return (
    <section className="phonelogin-container">
      
      {deletedStatus && deletedStatus === "Deleted" ? (
        <div className="card">
          <h1>Your account has been deleted, please contact Admin.</h1>
        </div>
      ) : (
        <div className="phonelogin-content">
        <Toaster toastOptions={{ duration: 4000 }} />
        <div id="recaptcha-container"></div>
        
        <div className="phonelogin-form">
          <h1 className="phonelogin-heading primary">
            Welcome to <br /> MPS Alumni
          </h1>
          {showOTP ? (
            <>
              <div >
                <img className="login__logo" src={Logo} alt="" />
              </div>
                <label className="phonelogin-label">Global Business Group</label>
              <label htmlFor="otp" className="phonelogin-label">
                Enter your OTP
              </label>
              <OtpInput
                value={otp}
                onChange={setOtp}
                OTPLength={6}
                otpType="number"
                disabled={false}
                autoFocus
                className="phonelogin-input otp-container"
              />
              <button
                onClick={onOTPVerify}
                className="phonelogin-button"
              >
                {loading && (
                  <CgSpinner size={20} className="phonelogin-spinner" />
                )}
                Verify OTP
              </button>
            </>
          ) : (
            <>
              <div className="phonelogin-icon-wrapper">
                {/* <BsTelephoneFill size={30} className="phonelogin-icon" /> */}
                <img className="login__logo" src={Logo} alt="" />
              </div>
              <label className="phonelogin-label">Global Business Group</label>
              <label htmlFor="" className="phonelogin-label">
                Verify your phone number
              </label>
              
              <PhoneInput
                country={"in"}
                // value={ph}
                onChange={handleOnChange}
                inputClass="phonelogin-input"
                // onlyCountries={["in"]}
                countryCodeEditable={true}
              />
              <button onClick={onSignup} className="phonelogin-button primary">
                {loading && (
                  <CgSpinner size={20} className="phonelogin-spinner" />
                )}
                <span className="phonelogin-button-text">
                  Send code via SMS
                </span>
              </button>
            </>
          )}
        </div>
        {/* )} */}
      </div>
      )}
    </section>
  );
};

export default Phonelogin;
