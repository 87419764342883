import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../assets/AdminNavbar.css";
import { toast } from "react-toastify";
import { auth } from "../Firebase";
import Logo from "../assets/img/logo (4).png";

const Navbar = () => {
  const navigate = useNavigate();
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        navigate("/",{replace: true});
        window.location.reload();
        toast.success("Logout Successfully");
        localStorage.removeItem("user");
        localStorage.removeItem("uid");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  return (
    // <div>
    //   <nav className="admin_navbar">
    //     <div className="nav__logo">
    //       <img className="img__logo" src="https://mpskalwarroad.com/assets/images/mps_kalwarroad.png" alt="" />
    //     </div>
    //     <div className="nav__links">
    //       <ul>
    //         <Link onClick={handleLogout} className="nav__link">
    //           Logout
    //         </Link>
    //       </ul>
    //     </div>
    //   </nav>
    // </div>

    <nav className="navbar">
      <img
        className="img__logo"
        src={Logo}
        alt=""
      />

      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li className="nav-item">
        <Link onClick={handleLogout} className="nav-links">
               Logout
             </Link>
        </li>
        
      </ul>
      <div className="nav-icon" onClick={handleClick}>
        <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
    </nav>
  );
};

export default Navbar;
