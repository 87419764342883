import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import $ from "jquery";
import "datatables.net";
import "../assets/Tables.css";
import "../assets/AdminDashboard.css";
import { onValue, ref, update } from "firebase/database";
import { auth, database } from "../Firebase";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Chatbot from "../shared/Chatbot";

const AdminList = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const dataTableRef = useRef(null);
  const navigate = useNavigate();
  const id = useParams();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    if (users.length > 0 && dataTableRef.current) {
      $(dataTableRef.current).DataTable();
    }
  }, [users]);

  const userId = localStorage.getItem("uid");

  useEffect(() => {
    const userRef = ref(database, `Users`);
    onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const userData = snapshot.val();
        const Data = Object.values(userData);
        const filteredData = Data.filter(
          (item) =>
            item.id !== userId &&
            item.status === true &&
            item.name !== undefined &&
            item.role !== "Student"
        );
        setUsers(filteredData);
        setIsLoading(false); // Set loading state to false once data is fetched
      }
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpen = (id) => {
    const userData = users.filter((user) => user.id === id);
    navigate(`/fulldata`, { state: { users: userData } });
  };

  const handleRole = async (userId) => {
    const userData = users.filter((user) => user.id === userId);
    // Change the Role to Student
    let payload = {
      role: "Student",
    };
    try {
      await update(ref(database, `Users/${userId}`), payload);
      toast.success(`${userData[0].name} is now Student`);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#my-table" });
    doc.save("table.pdf");
  };

  const generateXML = () => {
    const xmlHeader = '<?xml version="1.0" encoding="UTF-8"?>';
    let xmlContent = "<table>";

    users.forEach((user) => {
      xmlContent += `
        <row>
          <photo>${user.schoolImg}</photo>
          <name>${user.name}</name>
          <email>${user.email}</email>
          <mobile>${user.mobile_no}</mobile>
          <house>${user.schoolHouse}</house>
          <leaving>${user.leaving}</leaving>
          <profession>${user.profession}</profession>
          <jobProfile>${user.jobProfile}</jobProfile>
        </row>
      `;
    });

    xmlContent += "</table>";

    const xmlData = xmlHeader + xmlContent;
    const xmlBlob = new Blob([xmlData], { type: "text/xml" });
    const xmlUrl = URL.createObjectURL(xmlBlob);
    const link = document.createElement("a");
    link.href = xmlUrl;
    link.download = "table.xml";
    link.click();
  };

  return (
    <div>
      {window.innerWidth >= 768 && <Navbar />}
      <Sidebar />
      <Chatbot />
      <div className="admin__section">
        <h2 className="admin_heading">Admin List</h2>
      <div className="main__container">
        {isLoading ? ( // Render loader if loading state is true
          <div className="loader"></div>
        ) : users && users.length > 0 ? (
          <div className="table-container table-responsive">
            <table
              id="my-table"
              className="display"
              style={{ width: "100%" }}
              ref={dataTableRef}
            >
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Student Photo</th>
                  <th style={{ textAlign: "center" }}>Student Name</th>
                  <th style={{ textAlign: "center" }}>Student Email</th>
                  <th style={{ textAlign: "center" }}>Student DOB</th>
                  <th style={{ textAlign: "center" }}>Mobile Number</th>
                  <th style={{ textAlign: "center" }}>School House</th>
                  <th style={{ textAlign: "center" }}>School Leaving Year</th>
                  <th style={{ textAlign: "center" }}>Profession</th>
                  <th style={{ textAlign: "center" }}>Job Profile</th>
                  {/* {userId === "ur5D8WdyryUoYLXyu86sHX1hbeJ2" && (
                    <>
                    <th style={{ textAlign: "center" }}>View Details</th>
                    <th style={{ textAlign: "center" }}>Role</th>
                    </>
                  )} */}
                  {userId === "hwcY2kOoG7crVZaJr4RkwLE01w02" && (
                    <>
                    <th style={{ textAlign: "center" }}>View Details</th>
                    <th style={{ textAlign: "center" }}>Role</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <img className="user__img" src={user.schoolImg} alt="" />
                    </td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.dob || "N/A"}</td>
                    <td>{user.mobile_no}</td>
                    <td>{user.schoolHouse}</td>
                    <td>{user.leaving}</td>
                    <td>{user.profession}</td>
                    <td>{user.jobProfile}</td>
                    {/* {userId === "ur5D8WdyryUoYLXyu86sHX1hbeJ2" && (
                      <>
                        <td>
                          <button
                            onClick={() => handleOpen(user.id)}
                            className="verify__btn"
                          >
                            Edit
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => handleRole(user.id)}
                            className="verify__btn"
                          >
                            Make Admin
                          </button>
                        </td>
                      </>
                    )} */}
                    {userId === "hwcY2kOoG7crVZaJr4RkwLE01w02" && (
                      <>
                        <td>
                          <button
                            onClick={() => handleOpen(user.id)}
                            className="verify__btn"
                          >
                            Edit
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => handleRole(user.id)}
                            className="verify__btn"
                          >
                            Make Student
                          </button>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="donwload__buttons">
              <button className="button__download" onClick={generatePDF}>
                <i class="fa fa-download" aria-hidden="true"></i>Download as PDF
              </button>
              <button className="button__download" onClick={generateXML}>
                <i class="fa fa-download" aria-hidden="true"></i>Download as XML
              </button>
            </div>
          </div>
        ) : (
          <div className="no-data">No data available</div> // Render "No data" message if there are no users
        )}
      </div>
      </div>
    </div>
  );
};

export default AdminList;
