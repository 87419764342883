import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import $ from "jquery";
import "datatables.net";
import "../assets/Tables.css";
import "../assets/AdminDashboard.css";
import { onValue, ref, update } from "firebase/database";
import { auth, database } from "../Firebase";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Chatbot from "../shared/Chatbot";
import Footer from "../shared/Footer";

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const dataTableRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (users.length > 0 && dataTableRef.current) {
      $(dataTableRef.current).DataTable();
    }
  }, [users]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const userId = localStorage.getItem("uid");

  useEffect(() => {
    const userRef = ref(database, `Users`);
    onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const userData = snapshot.val();
        const Data = Object.values(userData);

        const filteredData = Data.filter(
          (item) => item.id !== userId && item.status == false
        );

        setUsers(filteredData);
      }
    });
  }, []);

  const handleVerify = async (userId) => {
    let payload = {
      status: true,
    };
    try {
      await update(ref(database, `Users/${userId}`), payload);
      toast.success("Verified Successfully!!");
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
    }
  };

  return (
    <div>
      {window.innerWidth >= 768 && <Navbar />}
      <Sidebar />
      <Chatbot />
      <div className="admin__section">
        <h2 className="admin_heading">Dashboard</h2>
        <div className="main__container">
          <div className="cards_container">
            <div class="card_box bg-c-blue order-card">
              <div class="card-block">
                <i
                  className="fa-solid fa-check cards_icon"
                  aria-hidden="true"
                ></i>
                <h4 className="card_info">
                  Verified <br /> Students
                </h4>
                <Link className="dashborad_links" to="/students">
                  <button className="button__dashboard">View &rarr;</button>
                </Link>
              </div>
            </div>

            <div class="card_box bg-c-green order-card">
              <div class="card-block">
                <i
                  className="fa-solid fa-user-plus cards_icon"
                  aria-hidden="true"
                ></i>
                <h4 className="card_info">
                  Unverified <br /> Students
                </h4>
                <Link className="dashborad_links" to="/request">
                  <button className="button__dashboard">View &rarr;</button>
                </Link>
              </div>
            </div>

            <div class="card_box bg-c-yellow order-card">
              <div class="card-block">
                <i
                  className="fa-solid fa-user-minus cards_icon"
                  aria-hidden="true"
                ></i>
                <h4 className="card_info">
                  Registered <br /> Students
                </h4>
                <Link className="dashborad_links" to="/registered">
                  <button className="button__dashboard">View &rarr;</button>
                </Link>
              </div>
            </div>
            {userId === "ur5D8WdyryUoYLXyu86sHX1hbeJ2" && (
              <div class="card_box bg-c-pink order-card">
                <div class="card-block">
                  <i
                    className="fa-solid fa-users cards_icon"
                    aria-hidden="true"
                  ></i>
                  <h4 className="card_info">
                    Admins <br /> List
                  </h4>
                  <Link className="dashborad_links" to="/adminList">
                    <button className="button__dashboard">View &rarr;</button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AdminDashboard;
