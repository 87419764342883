import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { auth, database } from "../Firebase";
import { onValue, onvalue, ref, set, update } from "firebase/database";
import { toast } from "react-toastify";
import Chatbot from "../shared/Chatbot";

const FullExcelData = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState();
  const [dob, setDob] = useState();
  const [dom, setDom] = useState();

  const [education, setEducation] = useState("");
  const [profession, setProfession] = useState("");
  const [jobProfile, setJobProfile] = useState("");
  const [companyName, setCompanyName] = useState("");
  
  const [address1, setAddress1] = useState("");
  const [leavingYear, setLeavingYear] = useState("");;
  const [bloodGroup, setBloodGroup] = useState("");

  const [status, setStatus] = useState(false);
  const location = useLocation();
  const [newUserData1, setNewUserData1] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const users = location?.state?.users || "N/A";
  

  const usersData = localStorage.setItem("users", JSON.stringify(users[0]));


  const firstName = newUserData1.mobile_no;

  useEffect(() => {
    const newUserData = localStorage.getItem("users");
    const parsedData = JSON.parse(newUserData);
    setNewUserData1(parsedData);
  }, []); 

  useEffect(() => {
    const userRef = ref(database, `ExcelData/${firstName}`);
    onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const userDatabase = snapshot.val();
        if (userDatabase) {
          const userData = userDatabase;
          setName(userData.name);
          setLeavingYear(userData.leaving);
          setBloodGroup(userData.bloodGroup);
          setCompanyName(userData.companyName);
          setDob(userData.dob);
          setDom(userData.dom);
          setJobProfile(userData.jobProfile);
          setAddress1(userData.address);
        
          setProfession(userData.profession);
          setEmail(userData.email);
           
        }
      }
    });
  }, [newUserData1]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
 

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission

    let payload = {
      name: name,
      email: email,
      address: address1 || "N/A",
      dob: dob,
      dom: dom || "N/A",
      bloodGroup: bloodGroup,
      leaving: leavingYear,
      profession: profession || "N/A",
      companyName: companyName,
      status: status,
    };

   

    try {
      await update(ref(database, `ExcelData/${firstName}`), payload);
      navigate("/unregister");
      toast.success("Updated Successfully!!");
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
    }
  };



  return (
    <>
      {window.innerWidth >= 768 && <Navbar />}
      <Sidebar />
      <Chatbot />

      <div className="">
        <div className="Section">
          <div class="register_container update__fulldata" id="reg-form">
            <div class="title">Registration</div>
       
            {/* <form className="reg__form" onSubmit={handleSubmit}> */}
            <div className="personal_details ">
              <h2 className="details_heading">Personal Details</h2>
              <div className="peronal_inputs ">
                <div class="input-box">
                  <span class="details">
                    Full Name <span className="validation-star">*</span>{" "}
                  </span>

                  <input
                    type="text"
                    value={newUserData1.name === "na" ? "" : name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name"
                    className="input_font"
                    readOnly={false}
                  />
                </div> 

                <div class="input-box">
                  <span class="details">
                    Email <span className="validation-star">*</span>
                  </span>

                  <input
                    type="text"
                    value={newUserData1.email === "na" ? "" : email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="input_font"
                    readOnly={false}
                  />
                </div>

                <div class="input-box">
                  <span class="details">
                    DOB <span className="validation-star">*</span>
                  </span>

                  <input
                    type="date"
                    value={newUserData1.dob === "na" ? "" : dob}
                    onChange={(e) => setDob(e.target.value)}
                    placeholder="Enter your number"
                    className="input_font"
                    readOnly={false}
                  />
                </div>

                <div class="input-box">
                  <span class="details">Date of Marriage</span>
                  <input
                    type="date"
                    value={newUserData1.dom === "na" ? "" : dom}
                    readOnly={false}
                    onChange={(e) => setDom(e.target.value)}
                    placeholder="Enter your number"
                    className="input_font"
                  />
                </div>

                <div class="input-box">
                  <span class="details">
                    Blood Group <span className="validation-star">*</span>
                  </span>

                  <input
                    type="text"
                    value={newUserData1.bloodGroup === "na" ? "" : bloodGroup}
                    onChange={(e) => setBloodGroup(e.target.value)}
                    placeholder="Enter your Blood Grroup"
                    className="input_font"
                    contentEditable
                    readOnly={false}
                  />
                </div>

                <div class="input-box">
                  <span class="details">Address</span>
                  <input
                    type="text"
                    value={newUserData1.address1 === "na" ? "" : address1}
                    onChange={(e) => setAddress1(e.target.value)}
                    placeholder="Enter your address line-1"
                    className="input_font"
                    readOnly={false}
                  />
                </div>
               
        


              </div>
            </div>

            <div className="education_details">
              <h2 className="details_heading">Education Details</h2>
              

              <div class="input-box">
                <span class="details">
                  School Leaving Year <span className="validation-star">*</span>
                </span>

                <input
                  type="number"
                  value={newUserData1.leavingYear === "na" ? "" : leavingYear}
                  readOnly={false}
                  onChange={(e) => setLeavingYear(e.target.value)}
                  placeholder="YYYY"
                  className="input_font"
                />
              </div>

              <div class="input-box">
                <span class="details">
                  Education <span className="validation-star">*</span>
                </span>

                <input
                  type="text"
                  value={newUserData1.education === "na" ? "" : education}
                  readOnly={false}
                  onChange={(e) => setEducation(e.target.value)}
                  placeholder="Enter your education"
                  className="input_font"
                />
              </div>
            </div>

            <div className="professional_details">
              <h2 className="details_heading">Professional Details</h2>
              <div className="professional_inputs">
              <div class="input-box">
                  <span class="details">Job Profile</span>
                  <input
                    type="text"
                    value={newUserData1.jobProfile === "na" ? "" : jobProfile}
                    onChange={(e) => setJobProfile(e.target.value)}
                    placeholder="Enter your Job Profile"
                    readOnly={false}
                    className="input_font"
                  />
                </div>
                <div class="input-box">
                  <span class="details">Profession</span>
                  <input
                    type="text"
                    value={newUserData1.profession === "na" ? "" : profession}
                    onChange={(e) => setProfession(e.target.value)}
                    placeholder="Enter your Profession"
                    readOnly={false}
                    className="input_font"
                  />
                </div>

           
                <div class="input-box">
                  <span class="details">
                    Company Name <span className="validation-star">*</span>
                  </span>

                  <input
                    type="text"
                    value={newUserData1.companyName === "na" ? "" : companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder="Enter your Company Name"
                    readOnly={false}
                    className="input_font"
                  /> 
                </div>
          
          
          
            
              </div>
            </div>

            <button onClick={handleSubmit} className="button__btn">
              Update
            </button>
            {/* </form> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FullExcelData;
