import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

import { auth, database } from "../Firebase";
import { onValue, onvalue, ref, set, update } from "firebase/database";
import { toast } from "react-toastify";
import Sidebar from "./Sidebar";
import Chatbot from "../shared/Chatbot";

const FullData = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState();
  const [dob, setDob] = useState();
  const [dom, setDom] = useState();

  const [schoolHouse, setSchoolHouse] = useState("");
  const [education, setEducation] = useState("");
  const [profession, setProfession] = useState("");
  const [jobProfile, setJobProfile] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress1, setCompanyAddress1] = useState("");
  const [companyAddress2, setCompanyAddress2] = useState("");
  const [companyAddress3, setCompanyAddress3] = useState("");
  const [companyPincode, setCompanyPincode] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyCountry, setCompanyCountry] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [pincode, setPincode] = useState("");
  const [leavingYear, setLeavingYear] = useState("");
  const [section, setSection] = useState("");
  const [lastClass, setLastClass] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [batch, setBatch] = useState("");
  const [status, setStatus] = useState(false);
  const location = useLocation();
  const [newUserData1, setNewUserData1] = useState([]);
  const [photo, setPhoto] = useState(
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
  );
  const [schoolPhoto, setSchoolPhoto] = useState(
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const users = location?.state?.users || "N/A";
  

  const usersData = localStorage.setItem("users", JSON.stringify(users[0]));

  const firstName = newUserData1.id;


  useEffect(() => {
    const newUserData = localStorage.getItem("users");
    const parsedData = JSON.parse(newUserData);
    setNewUserData1(parsedData);
  }, []); 

  useEffect(() => {
    const userRef = ref(database, `Users/${firstName}`);
    onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const userDatabase = snapshot.val();
        if (userDatabase) {
          const userData = userDatabase;
          setName(userData.name);
          setCity(userData.city);
          setCountry(userData.country);
          setPincode(userData.pincode); 
          setSection(userData.section);
          setBatch(userData.batch);
          setLeavingYear(userData.leaving);
          setBloodGroup(userData.bloodGroup);
          setAddress1(userData.address1);
          setAddress2(userData.address2);
          setAddress3(userData.address3);
          setCompanyName(userData.companyName);
          setDob(userData.dob);
          setDom(userData.dom);
          setEmail(userData.email);
          setEducation(userData.education);
          setJobProfile(userData.jobProfile);
          setLastClass(userData.lastClass);
          setSchoolHouse(userData.schoolHouse);
          setSchoolPhoto(userData.schoolImg);
          setProfession(userData.profession)
          setPhoto(userData.img);
          setCompanyAddress1(userData.companyAddress1);
          setCompanyAddress2(userData.companyAddress2);
          setCompanyAddress3(userData.companyAddress3);
          setCompanyCity(userData.companyCity);
          setCompanyCountry(userData.companyCountry);
          setCompanyPincode(userData.companyPincode);          
        }
      }
    });
  }, [newUserData1]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission

    let payload = {
      name: name,
      email: email,
      address: address1 || "N/A",
      address2: address2 || "N/A",
      address3: address3 || "N/A",
      pincode: pincode || "N/A",
      city: city,
      country: country || "N/A",
      dob: dob,
      dom: dom || "N/A",
      bloodGroup: bloodGroup,
      schoolHouse: schoolHouse,
      section: section,
      batch: batch || "N/A",
      lastClass: lastClass,
      leaving: leavingYear,
      education: education,
      profession: profession || "N/A",
      jobProfile: jobProfile || "N/A",
      companyName: companyName,
      companyCity: companyCity,
      companyCountry: companyCountry || "N/A",
      companyAddress: companyAddress1 || "N/A",
      companyAddress2: companyAddress2 || "N/A",
      companyAddress3: companyAddress3 || "N/A",
      companyPincode: companyPincode || "N/A",
      schoolImg: schoolPhoto || "N/A",
      img: photo || "N/A",
      private: {
        a: "b",
      },
      status: status,
    };

    

    try {
      await update(ref(database, `Users/${firstName}`), payload);
      navigate("/students");
      toast.success("Updated Successfully!!");
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
    }
  };

 

  // Handling Current Photo of Student
  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result;
      setPhoto(base64Image);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Handling School Photo of Student
  const handleSchoolPhotoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result;
      setSchoolPhoto(base64Image);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      {window.innerWidth >= 768 && <Navbar />}
      <Sidebar />
      <Chatbot />

      <div className="">
        <div className="Section">
          <div class="register_container update__fulldata" id="reg-form">
            <div class="title">Update Data</div>
            <div className="images_Container">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="photoUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handlePhotoChange}
                  />
                  <label htmlFor="photoUpload"></label>
                </div>
                <div className="avatar-preview">
                  <div
                    id="imagePreview"
                    style={{
                      backgroundImage: `url(${
                        photo === "NA"
                          ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          : photo
                      })`,
                    }}
                  ></div>
                </div>
                <h4 className="design-name">Upload Current Photo</h4>
              </div>
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="schoolImageUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleSchoolPhotoChange}
                  />
                  <label htmlFor="schoolImageUpload"></label>
                </div>
                <div className="avatar-preview">
                  <div
                    id="imagePreview"
                    style={{
                      backgroundImage: `url(${
                        schoolPhoto === "NA"
                          ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          : schoolPhoto
                      })`,
                    }}
                  ></div>
                </div>
                <h4 className="design-name">Upload School Photo</h4>
              </div>
            </div>
            {/* <form className="reg__form" onSubmit={handleSubmit}> */}
            <div className="personal_details reg__form">
              <h2 className="details_heading">Personal Details</h2>
              <div className="peronal_inputs">
                <div class="input-box">
                  <span class="details">
                    Full Name <span className="validation-star">*</span>{" "}
                  </span>

                  <input
                    type="text"
                    value={newUserData1.name === "na" ? "" : name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name"
                    className="input_font"
                    readOnly={false}
                  />
                </div>

                <div class="input-box">
                  <span class="details">
                    Email <span className="validation-star">*</span>
                  </span>

                  <input
                    type="text"
                    value={newUserData1.email === "na" ? "" : email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="input_font"
                    readOnly={false}
                  />
                </div>

                <div class="input-box">
                  <span class="details">
                    DOB <span className="validation-star">*</span>
                  </span>

                  <input
                    type="date"
                    value={newUserData1.dob === "na" ? "" : dob}
                    onChange={(e) => setDob(e.target.value)}
                    placeholder="Enter your number"
                    className="input_font"
                    readOnly={false}
                  />
                </div>

                <div class="input-box">
                  <span class="details">Date of Marriage</span>
                  <input
                    type="date"
                    value={newUserData1.dom === "na" ? "" : dom}
                    readOnly={false}
                    onChange={(e) => setDom(e.target.value)}
                    placeholder="Enter your number"
                    className="input_font"
                  />
                </div>

                <div class="input-box">
                  <span class="details">
                    Blood Group <span className="validation-star">*</span>
                  </span>

                  <input
                    type="text"
                    value={newUserData1.bloodGroup === "na" ? "" : bloodGroup}
                    onChange={(e) => setBloodGroup(e.target.value)}
                    placeholder="Enter your Blood Grroup"
                    className="input_font"
                    contentEditable
                    readOnly={false}
                  />
                </div>

                <div class="input-box">
                  <span class="details">Address Line-1</span>
                  <input
                    type="text"
                    value={newUserData1.address1 === "na" ? "" : address1}
                    onChange={(e) => setAddress1(e.target.value)}
                    placeholder="Enter your address line-1"
                    className="input_font"
                    readOnly={false}
                  />
                </div>
                <div class="input-box">
                  <span class="details">Address Line-2</span>
                  <input
                    type="text"
                    value={newUserData1.address2 === "na" ? "" : address2}
                    onChange={(e) => setAddress2(e.target.value)}
                    placeholder="Enter your address line-2"
                    className="input_font"
                    readOnly={false}
                  />
                </div>
                <div class="input-box">
                  <span class="details">Address Line-3</span>
                  <input
                    type="text"
                    value={newUserData1.address3 === "na" ? "" : address3}
                    onChange={(e) => setAddress3(e.target.value)}
                    placeholder="Enter your address line-3"
                    className="input_font"
                    readOnly={false}
                  />
                </div>
                <div class="input-box">
                  <span class="details">
                    City <span className="validation-star">*</span>
                  </span>

                  <input
                    type="text"
                    value={newUserData1.city === "na" ? "" : city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="Enter your city"
                    className="input_font"
                    readOnly={false}
                  />
                </div>

                <div class="input-box">
                  <span class="details">Country</span>
                  <input
                    type="text"
                    value={newUserData1.country === "na" ? "" : country}
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder="Enter your Country"
                    className="input_font"
                    readOnly={false}
                  />
                </div>

                <div class="input-box">
                  <span class="details">Pincode</span>
                  <input
                    type="number"
                    value={newUserData1.pincode === "na" ? "" : pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    placeholder="Enter your Pincode"
                    className="input_font"
                    readOnly={false}
                  />
                </div>
              </div>
            </div>

            <div className="education_details">
              <h2 className="details_heading">Education Details</h2>
              <div className="education_inputs">
                <div class="input-box">
                  <span class="details">
                    School House <span className="validation-star">*</span>
                  </span>

                  <input
                    type="text"
                    value={newUserData1.schoolHouse  === "na" ? "" : schoolHouse}
                    onChange={(e) => setSchoolHouse(e.target.value)}
                    placeholder="Enter your School House"
                    className="input_font"
                    readOnly={false}
                  />
                </div>

                <div class="input-box">
                  <span class="details">
                    Last Class Attended{" "}
                    <span className="validation-star">*</span>
                  </span>
                  <div className="last-class">
                    <select
                      value={newUserData1.lastClass === "na" ? "" : lastClass}
                      onChange={(e) => setLastClass(e.target.value)}
                      readOnly={false}
                    >
                      <option disabled selected hidden>
                        Class
                      </option>
                      <option value="1">I</option>
                      <option value="2">II</option>
                      <option value="3">III</option>
                      <option value="4">IV</option>
                      <option value="5">V</option>
                      <option value="6">VI</option>
                      <option value="7">VII</option>
                      <option value="8">VIII</option>
                      <option value="9">IX</option>
                      <option value="10">X</option>
                      <option value="11">XI</option>
                      <option value="12">XII</option>
                    </select>
                  </div>

                  <select
                    value={newUserData1.section === "na" ? "" : section}
                    onChange={(e) => setSection(e.target.value)}
                  >
                    <option disabled selected hidden>
                      Section
                    </option>
                    <option>A1</option>
                    <option>A2</option>
                    <option>A3</option>
                    <option>A4</option>
                    <option>A5</option>
                    <option>A6</option>
                  </select>
                </div>
              </div>

              <div class="input-box">
                <span class="details">
                  School Leaving Year <span className="validation-star">*</span>
                </span>

                <input
                  type="number"
                  value={newUserData1.leavingYear === "na" ? "" : leavingYear}
                  readOnly={false}
                  onChange={(e) => setLeavingYear(e.target.value)}
                  placeholder="YYYY"
                  className="input_font"
                />
              </div>

              <div class="input-box">
                <span class="details">
                  Education <span className="validation-star">*</span>
                </span>

                <input
                  type="text"
                  value={newUserData1.education === "na" ? "" : education}
                  readOnly={false}
                  onChange={(e) => setEducation(e.target.value)}
                  placeholder="Enter your education"
                  className="input_font"
                />
              </div>
            </div>

            <div className="professional_details">
              <h2 className="details_heading">Professional Details</h2>
              <div className="professional_inputs">
                <div class="input-box">
                  <span class="details">Profession</span>
                  <input
                    type="text"
                    value={newUserData1.profession === "na" ? "" : profession}
                    onChange={(e) => setProfession(e.target.value)}
                    placeholder="Enter your Profession"
                    readOnly={false}
                    className="input_font"
                  />
                </div>

                <div class="input-box">
                  <span class="details">Job Profile</span>
                  <input
                    type="text"
                    value={newUserData1.jobProfile === "na" ? "" : jobProfile}
                    onChange={(e) => setJobProfile(e.target.value)}
                    placeholder="Enter your Job Profile"
                    readOnly={false}
                    className="input_font"
                  />
                </div>
                <div class="input-box">
                  <span class="details">
                    Company Name <span className="validation-star">*</span>
                  </span>

                  <input
                    type="text"
                    value={newUserData1.companyName === "na" ? "" : companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder="Enter your Company Name"
                    readOnly={false}
                    className="input_font"
                  />
                </div>
                <div class="input-box">
                  <span class="details">Company Address Line-1</span>
                  <input
                    type="text"
                    value={newUserData1.companyAddress1 === "na" ? "" : companyAddress1}
                    onChange={(e) => setCompanyAddress1(e.target.value)}
                    placeholder="Enter Company Address Line-1"
                    className="input_font"
                    readOnly={false}
                  />
                </div>
                <div class="input-box">
                  <span class="details">Company Address Line-2</span>
                  <input
                    type="text"
                    value={newUserData1.companyAddress2 === "na" ? "" : companyAddress2}
                    onChange={(e) => setCompanyAddress2(e.target.value)}
                    placeholder="Enter Company Address Line-2"
                    className="input_font"
                    readOnly={false}
                  />
                </div>
                <div class="input-box">
                  <span class="details">Company Address Line-3</span>
                  <input
                    type="text"
                    value={newUserData1.companyAddress3 === "na" ? "" : companyAddress3}
                    onChange={(e) => setCompanyAddress3(e.target.value)}
                    placeholder="Enter Company Address Line-3"
                    className="input_font"
                    readOnly={false}
                  />
                </div>
                <div class="input-box">
                  <span class="details">
                    Company City <span className="validation-star">*</span>
                  </span>

                  <input
                    type="text"
                    value={newUserData1.companyCity === "na" ? "" : companyCity}
                    onChange={(e) => setCompanyCity(e.target.value)}
                    placeholder="Enter your Company City"
                    className="input_font"
                    readOnly={false}
                  />
                </div>
                <div class="input-box">
                  <span class="details">Company Country</span>
                  <input
                    type="text"
                    value={newUserData1.companyCountry === "na" ? "" : companyCountry}
                    onChange={(e) => setCompanyCountry(e.target.value)}
                    placeholder="Enter your Company Country"
                    className="input_font"
                    readOnly={false}
                  />
                </div>
                <div class="input-box">
                  <span class="details">Company Pincode</span>
                  <input
                    type="number"
                    value={newUserData1.companyPincode === "na" ? "" : companyPincode}
                    onChange={(e) => setCompanyPincode(e.target.value)}
                    placeholder="Enter Company Pincode"
                    className="input_font"
                    readOnly={false}
                  />
                </div>
              </div>
            </div>

            <button onClick={handleSubmit} className="button__btn">
              Update
            </button>
            {/* </form> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FullData;
