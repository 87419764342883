import React from "react";
import "../assets/Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer_section">
      <footer class="footer">
        <div className="contact_container">
          <strong>Global Business Group</strong>
        </div>
        <div className="app-links_container">
          <h3 className="link_heading">Download Our App</h3>
          <div className="app_links">
            <Link className="link" to="https://play.google.com/store/apps/details?id=com.sayana.mps" target="_blank">
            <div className="android">
              <i class="fa-brands fa-google-play fa-bounce"></i>
              <div>
              <span>get it on</span>
              <h5>google play</h5>
              </div>
            </div>
            </Link>
            
            <Link className="link" to="https://apps.apple.com/in/app/mps-global-business-group/id6450056213" target="_blank">
            <div className="ios">
              <i class="fa-brands fa-apple fa-bounce"></i>
              <div>
              <span>get it on</span>
              <h5>app store</h5>
              </div>
            </div>
            </Link>
          </div>
        </div>
        
      </footer>
      <p className="copyright">&copy; Copyright MPS-Alumni. All Rights Reserved</p>
    </div>
  );
};

export default Footer;
