import React, { useEffect, useState } from "react";
import "../assets/Register.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { auth, database } from "../Firebase";
import { onValue, ref, update, remove } from "firebase/database";
import Navbar from "../shared/sNavbar";
import { useLocation } from "react-router-dom";

const Register = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState();
  const [dob, setDob] = useState();
  const [dom, setDom] = useState();
  const [photo, setPhoto] = useState(
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
  );
  const [schoolPhoto, setSchoolPhoto] = useState(
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
  );
  const [schoolHouse, setSchoolHouse] = useState("");
  const [education, setEducation] = useState("");
  const [profession, setProfession] = useState("");
  const [jobProfile, setJobProfile] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress1, setCompanyAddress1] = useState("");
  const [companyAddress2, setCompanyAddress2] = useState("");
  const [companyAddress3, setCompanyAddress3] = useState("");
  const [companyPincode, setCompanyPincode] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyCountry, setCompanyCountry] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [pincode, setPincode] = useState("");
  const [leavingYear, setLeavingYear] = useState("");
  const [section, setSection] = useState("");
  const [lastClass, setLastClass] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [batch, setBatch] = useState("");
  const [hashTag, setHashTag] = useState("");
  const [status, setStatus] = useState(false);
  const [requiredFields, setRequiredFields] = useState([
    "name",
    "email",
    "city",
    "dob",
    "bloodGroup",
    "schoolHouse",
    "section",
    "lastClass",
    "leavingYear",
    "education",
    "companyName",
    "companyCity",
  ]);
  const [highlightedFields, setHighlightedFields] = useState([]);

  const userId = auth.currentUser.uid;
  console.log(userId);
  const navigate = useNavigate();
  const location = useLocation();
  const { countryCode } = location.state;

  const phone = auth.currentUser.phoneNumber;

  const codeL = countryCode.length + 1;

  var mobileNumber = phone.slice(codeL);

  // Handle Batch
  const handleBatch = (e) => {
    setBatch(e.target.value);
    document.getElementById("myModal").style.display = "none";
    document.getElementById("reg-form").style.display = "flex";
  };

  // Handling Current Photo of Student
  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result;
      setPhoto(base64Image);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Handling School Photo of Student
  const handleSchoolPhotoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result;
      setSchoolPhoto(base64Image);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Saving student data into DB
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the required fields are empty
    const emptyFields = requiredFields.filter((field) => !eval(field));

    if (emptyFields.length > 0) {
      toast.error("Please fill in all required fields.");

      // Set the highlighted fields
      setHighlightedFields(emptyFields);

      return;
    }
    let payload = {
      name: name,
      email: email,
      city: city,
      bloodGroup: bloodGroup,
      schoolHouse: schoolHouse,
      section: section,
      dob: dob,
      batch: batch || "N/A",
      lastClass: lastClass,
      leaving: leavingYear,
      profession: profession || "N/A",
      jobProfile: jobProfile || "N/A",
      companyCity: companyCity,
      section: section,
      searching:true,
      private: {
        a: "b",
        companyName: companyName,
        education: education,
        schoolImg: schoolPhoto || "NA",
        img: photo || "N/A",
        address: address1 || "N/A",
        address2: address2 || "N/A",
        address3: address3 || "N/A",
        pincode: pincode || "N/A",
        country: country || "N/A",
        dom: dom || "N/A",
        schoolHouse: schoolHouse,

        companyCountry: companyCountry || "N/A",
        companyAddress: companyAddress1 || "N/A",
        companyAddress2: companyAddress2 || "N/A",
        companyAddress3: companyAddress3 || "N/A",
        companyPincode: companyPincode || "N/A",
      },
      hashtag: hashTag || "N/A",
      status: status,
    };

    try {
      await update(ref(database, `Users/${userId}`), payload);
      navigate("/Dashboard");
      toast.success("Registered Successfully!!");
      if (status === true) {
        remove(ref(database, `ExcelData/${mobileNumber}`)).then();
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
    }
  };

  // Check the Mobile Number is exist or not
  // If exist then preview it's data
  useEffect(() => {
    const userRef = ref(database, `ExcelData/${mobileNumber}`);
    onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const userExcelData = snapshot.val();
        if (userExcelData) {
          const userData = userExcelData;
          setBloodGroup(userData.bloodGroup);
          setAddress1(userData.address);
          setCompanyName(userData.companyName);
          setEmail(userData.email);
          setEducation(userData.education);
          setJobProfile(userData.jobProfile);
          setLastClass(userData.lastClass);
          setSchoolHouse(userData.schoolHouse);
          setSchoolPhoto(userData.schoolImg);
          setProfession(userData.profession);
          setName(userData.name);
          setStatus(true);
        }
      }
    });
  }, []);

 

  const handleDob = (e) => {
    const date = e.target.value;
    const formatedDate = formatDate(date);
    setDob(formatedDate);
  };

  const handleDom = (e) => {
    const date = e.target.value;
    const formatedDate = formatDate(date);
    setDom(formatedDate);
  };

  const formatDate = (inputDate) => {
    const dateObj = new Date(inputDate);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    // Month is zero-based
    const year = dateObj.getFullYear();
    return `${day}-${month}-${year}`;
  };


  return (
    <>
      <Navbar />
      <div className="view__container">
        <div className="Section">
          <div id="myModal" class="reg_modal">
            <div class="modal-content">
              <h1>Welcome to MPS!</h1>
              <img
                className="login__logo"
                src="https://mpskalwarroad.com/assets/images/mps_kalwarroad.png"
                alt=""
              />
              <p>Please select Batch</p>
              <select id="selectValue" value={batch} onChange={handleBatch}>
                <option value="" disabled selected hidden>
                  Select Batch
                </option>
                <option value="1977">1977</option>
    <option value="1978">1978</option>
    <option value="1979">1979</option>
    <option value="1980">1980</option>
    <option value="1981">1981</option>
    <option value="1982">1982</option>
    <option value="1983">1983</option>
    <option value="1984">1984</option>
    <option value="1985">1985</option>
    <option value="1986">1986</option>
    <option value="1987">1987</option>
    <option value="1988">1988</option>
    <option value="1989">1989</option>
    <option value="1990">1990</option>
    <option value="1991">1991</option>
    <option value="1992">1992</option>
    <option value="1993">1993</option>
    <option value="1994">1994</option>
    <option value="1995">1995</option>
    <option value="1996">1996</option>
    <option value="1997">1997</option>
    <option value="1998">1998</option>
    <option value="1999">1999</option>
    <option value="2000">2000</option>
    <option value="2001">2001</option>
    <option value="2002">2002</option>
    <option value="2003">2003</option>
    <option value="2004">2004</option>
    <option value="2005">2005</option>
    <option value="2006">2006</option>
    <option value="2007">2007</option>
    <option value="2008">2008</option>
    <option value="2009">2009</option>
    <option value="2010">2010</option>
    <option value="2011">2011</option>
    <option value="2012">2012</option>
    <option value="2013">2013</option>
    <option value="2014">2014</option>
    <option value="2015">2015</option>
    <option value="2016">2016</option>
    <option value="2017">2017</option>
    <option value="2018">2018</option>
    <option value="2019">2019</option>
    <option value="2020">2020</option>
    <option value="2021">2021</option>
    <option value="2022">2022</option>
    <option value="2023">2023</option>
              </select>
            </div>
          </div>
          <div class="register_container" id="reg-form">
            <div class="title">Registration</div>
            <div className="images_Container">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="photoUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handlePhotoChange}
                  />
                  <label htmlFor="photoUpload"></label>
                </div>
                <div className="avatar-preview">
                  <div
                    id="imagePreview"
                    style={{
                      backgroundImage: `url(${
                        photo === "NA"
                          ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          : photo
                      })`,
                    }}
                  ></div>
                </div>
                <h4 className="design-name">Upload Current Photo</h4>
              </div>
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="schoolImageUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleSchoolPhotoChange}
                  />
                  <label htmlFor="schoolImageUpload"></label>
                </div>
                <div className="avatar-preview">
                  <div
                    id="imagePreview"
                    style={{
                      backgroundImage: `url(${
                        schoolPhoto === "NA"
                          ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          : schoolPhoto
                      })`,
                    }}
                  ></div>
                </div>
                <h4 className="design-name">Upload School Photo</h4>
              </div>
            </div>
            <form className="reg__form" onSubmit={handleSubmit}>
              <div className="personal_details">
                <h2 className="details_heading">Personal Details</h2>
                <div className="peronal_inputs">
                  <div class="input-box">
                    <span class="details">
                      Full Name <span className="validation-star">*</span>{" "}
                    </span>
                    <div
                      className={`input-box${
                        highlightedFields.includes("name") ? " highlight" : ""
                      }`}
                    >
                      <input
                        type="text"
                        value={name === "na" ? "" : name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                        className="input_font"
                      />
                    </div>
                  </div>

                  <div class="input-box">
                    <span class="details">
                      Email <span className="validation-star">*</span>
                    </span>
                    <div
                      className={`input-box${
                        highlightedFields.includes("name") ? " highlight" : ""
                      }`}
                    >
                      <input
                        type="text"
                        value={email === "na" ? "" : email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="input_font"
                      />
                    </div>
                  </div>

                  <div class="input-box">
                    <span class="details">
                      DOB <span className="validation-star">*</span>
                    </span>
                    <div
                      className={`input-box${
                        highlightedFields.includes("name") ? " highlight" : ""
                      }`}
                    >
                      <input
                        type="date"
                        onChange={handleDob}
                        className="input_font"
                      />
                    </div>
                  </div>

                  <div class="input-box">
                    <span class="details">Date of Marriage</span>
                    <input
                      type="date"
                      onChange={handleDom}
                      className="input_font"
                    />
                  </div>

                  <div class="input-box">
                    <span class="details">
                      Blood Group <span className="validation-star">*</span>
                    </span>
                    <div
                      className={`input-box${
                        highlightedFields.includes("name") ? " highlight" : ""
                      }`}
                    >
                      <input
                        type="text"
                        value={bloodGroup === "na" ? "" : bloodGroup}
                        onChange={(e) => setBloodGroup(e.target.value)}
                        placeholder="Enter your Blood Grroup"
                        className="input_font"
                        contentEditable
                      />
                    </div>
                  </div>

                  <div class="input-box">
                    <span class="details">Address Line-1</span>
                    <input
                      type="text"
                      value={address1 === "na" ? "" : address1}
                      onChange={(e) => setAddress1(e.target.value)}
                      placeholder="Enter your address line-1"
                      className="input_font"
                    />
                  </div>
                  <div class="input-box">
                    <span class="details">Address Line-2</span>
                    <input
                      type="text"
                      value={address2 || ""}
                      onChange={(e) => setAddress2(e.target.value)}
                      placeholder="Enter your address line-2"
                      className="input_font"
                    />
                  </div>
                  <div class="input-box">
                    <span class="details">Address Line-3</span>
                    <input
                      type="text"
                      value={address3 || ""}
                      onChange={(e) => setAddress3(e.target.value)}
                      placeholder="Enter your address line-3"
                      className="input_font"
                    />
                  </div>
                  <div class="input-box">
                    <span class="details">
                      City <span className="validation-star">*</span>
                    </span>
                    <div
                      className={`input-box${
                        highlightedFields.includes("name") ? " highlight" : ""
                      }`}
                    >
                      <input
                        type="text"
                        value={city || ""}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="Enter your city"
                        className="input_font"
                      />
                    </div>
                  </div>

                  <div class="input-box">
                    <span class="details">Country</span>
                    <input
                      type="text"
                      value={country || ""}
                      onChange={(e) => setCountry(e.target.value)}
                      placeholder="Enter your Country"
                      className="input_font"
                    />
                  </div>

                  <div class="input-box">
                    <span class="details">Pincode</span>
                    <input
                      type="number"
                      value={pincode || ""}
                      onChange={(e) => setPincode(e.target.value)}
                      placeholder="Enter your Pincode"
                      className="input_font"
                    />
                  </div>
                </div>
              </div>

              <div className="education_details">
                <h2 className="details_heading">Education Details</h2>
                <div className="education_inputs">
                  <div class="input-box">
                    <span class="details">
                      School House <span className="validation-star">*</span>
                    </span>
                    <div
                      className={`input-box${
                        highlightedFields.includes("name") ? " highlight" : ""
                      }`}
                    >
                      <input
                        type="text"
                        value={schoolHouse === "na" ? "" : schoolHouse}
                        onChange={(e) => setSchoolHouse(e.target.value)}
                        placeholder="Enter your School House"
                        className="input_font"
                      />
                    </div>
                  </div>

                  <div class="input-box">
                    <span class="details">
                      Last Class Attended{" "}
                      <span className="validation-star">*</span>
                    </span>
                    <div className="last-class">
                      <div
                        className={`input-box${
                          highlightedFields.includes("name") ? " highlight" : ""
                        }`}
                      >
                        <select
                          value={lastClass === "na" ? "" : lastClass}
                          onChange={(e) => setLastClass(e.target.value)}
                        >
                          <option selected>Class</option>
                          <option value="1">I</option>
                          <option value="2">II</option>
                          <option value="3">III</option>
                          <option value="4">IV</option>
                          <option value="5">V</option>
                          <option value="6">VI</option>
                          <option value="7">VII</option>
                          <option value="8">VIII</option>
                          <option value="9">IX</option>
                          <option value="10">X</option>
                          <option value="11">XI</option>
                          <option value="12">XII</option>
                        </select>
                      </div>
                      <div
                        className={`input-box${
                          highlightedFields.includes("name") ? " highlight" : ""
                        }`}
                      >
                        <select
                          value={section}
                          onChange={(e) => setSection(e.target.value)}
                        >
                          <option selected>Section</option>
                          <option>A1</option>
                          <option>A2</option>
                          <option>A3</option>
                          <option>A4</option>
                          <option>A5</option>
                          <option>A6</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="input-box">
                    <span class="details">
                      School Leaving Year{" "}
                      <span className="validation-star">*</span>
                    </span>
                    <div
                      className={`input-box${
                        highlightedFields.includes("name") ? " highlight" : ""
                      }`}
                    >
                      <input
                        type="number"
                        value={leavingYear}
                        onChange={(e) => setLeavingYear(e.target.value)}
                        placeholder="YYYY"
                        className="input_font"
                      />
                    </div>
                  </div>

                  <div class="input-box">
                    <span class="details">
                      Education <span className="validation-star">*</span>
                    </span>
                    <div
                      className={`input-box${
                        highlightedFields.includes("name") ? " highlight" : ""
                      }`}
                    >
                      <input
                        type="text"
                        value={education === "na" ? "" : education}
                        onChange={(e) => setEducation(e.target.value)}
                        placeholder="Enter your education"
                        className="input_font"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="professional_details">
                <h2 className="details_heading">Professional Details</h2>
                <div className="professional_inputs">
                  <div class="input-box">
                    <span class="details">Profession</span>
                    <input
                      type="text"
                      value={profession === "na" ? "" : profession}
                      onChange={(e) => setProfession(e.target.value)}
                      placeholder="Enter your Profession"
                      className="input_font"
                    />
                  </div>

                  <div class="input-box">
                    <span class="details">Job Profile</span>
                    <input
                      type="text"
                      value={jobProfile === "na" ? "" : jobProfile}
                      onChange={(e) => setJobProfile(e.target.value)}
                      placeholder="Enter your Job Profile"
                      className="input_font"
                    />
                  </div>
                  <div class="input-box">
                    <span class="details">
                      Company Name <span className="validation-star">*</span>
                    </span>
                    <div
                      className={`input-box${
                        highlightedFields.includes("name") ? " highlight" : ""
                      }`}
                    >
                      <input
                        type="text"
                        value={companyName === "na" ? "" : companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        placeholder="Enter your Company Name"
                        className="input_font"
                      />
                    </div>
                  </div>
                  <div class="input-box">
                    <span class="details">Company Address Line-1</span>
                    <input
                      type="text"
                      value={companyAddress1}
                      onChange={(e) => setCompanyAddress1(e.target.value)}
                      placeholder="Enter Company Address Line-1"
                      className="input_font"
                    />
                  </div>
                  <div class="input-box">
                    <span class="details">Company Address Line-2</span>
                    <input
                      type="text"
                      value={companyAddress2}
                      onChange={(e) => setCompanyAddress2(e.target.value)}
                      placeholder="Enter Company Address Line-2"
                      className="input_font"
                    />
                  </div>
                  <div class="input-box">
                    <span class="details">Company Address Line-3</span>
                    <input
                      type="text"
                      value={companyAddress3}
                      onChange={(e) => setCompanyAddress3(e.target.value)}
                      placeholder="Enter Company Address Line-3"
                      className="input_font"
                    />
                  </div>
                  <div class="input-box">
                    <span class="details">
                      Company City <span className="validation-star">*</span>
                    </span>
                    <div
                      className={`input-box${
                        highlightedFields.includes("name") ? " highlight" : ""
                      }`}
                    >
                      <input
                        type="text"
                        value={companyCity}
                        onChange={(e) => setCompanyCity(e.target.value)}
                        placeholder="Enter your Company City"
                        className="input_font"
                      />
                    </div>
                  </div>
                  <div class="input-box">
                    <span class="details">Company Country</span>
                    <input
                      type="text"
                      value={companyCountry}
                      onChange={(e) => setCompanyCountry(e.target.value)}
                      placeholder="Enter your Company Country"
                      className="input_font"
                    />
                  </div>
                  <div class="input-box">
                    <span class="details">Company Pincode</span>
                    <input
                      type="number"
                      value={companyPincode}
                      onChange={(e) => setCompanyPincode(e.target.value)}
                      placeholder="Enter Company Pincode"
                      className="input_font"
                    />
                  </div>
                  <div class="input-box">
                    <span class="details">Hashtag</span>
                    <input
                      type="text"
                      value={hashTag}
                      onChange={(e) => setHashTag(e.target.value)}
                      placeholder="Keywords related to your field"
                      className="input_font"
                    />
                  </div>
                </div>
              </div>

              <div>
                <input className="reg__btn" type="submit" value="Register" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
