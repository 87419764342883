import { initializeApp, firebase } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA8a2maKNUQRO3PEJdDocKB6q7mK42GNSE",
  authDomain: "school-directory-d1356.firebaseapp.com",
  databaseURL: "https://school-directory-d1356-default-rtdb.firebaseio.com",
  projectId: "school-directory-d1356",
  storageBucket: "school-directory-d1356.appspot.com",
  messagingSenderId: "122448203565",
  appId: "1:122448203565:web:88c279397dec843b770da4",
  measurementId: "G-XSYSQVGMMD"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCYmj4dqMbORhWC_G5Zsho9B9R-XeC0p38",
//   authDomain: "school-directory-93c55.firebaseapp.com",
//   projectId: "school-directory-93c55",
//   storageBucket: "school-directory-93c55.appspot.com",
//   messagingSenderId: "457739986028",
//   appId: "1:457739986028:web:8ba6b68bb9f2475f0ce449",
//   measurementId: "G-PD865SLJ8L"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const database = getDatabase(app);
export const storage = getStorage(app);
