import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ref, update } from "firebase/database";
import { database } from "../Firebase";
import $ from "jquery";
import "datatables.net";
import "../assets/Tables.css";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import moment from "moment";
import Chatbot from "../shared/Chatbot";
import Footer from "../shared/Footer";


const MultiUserDataUpload = () => {
  const [excelData, setExcelData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const dataTableRef = useRef(null);
  const sanitizeKey = (key) => {
    return key.replace(/[.#$/[\]]/g, "_");
  };

  // const handleFileChange = async (event) => {
  //   const excelFile = event.target.files[0];
  //   const reader = new FileReader();

  //   reader.onload = (e) => {
  //     const data = new Uint8Array(e.target.result);
  //     const workbook = XLSX.read(data, { type: "array" });
  //     const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //     const jsonData = XLSX.utils.sheet_to_json(worksheet);
  //     setExcelData(jsonData);
  //   };

  //   reader.readAsArrayBuffer(excelFile);
  // };

  const handleFileChange = async (event) => {
    const excelFile = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
  
      // Convert the dates to DD-MM-YYYY format
      const formattedData = jsonData.map((rowData) => {
        return {
          ...rowData,
          "DOB(DD MMM)": formatDate(rowData["DOB(DD MMM)"], "DD MMM", "DD-MM-YYYY"),
          "Date of Marriage (DD/MM)": formatDate(rowData["Date of Marriage (DD/MM)"], "DD/MM", "DD-MM-YYYY"),
        };
      });
  
      setExcelData(formattedData);
    };
  
    reader.readAsArrayBuffer(excelFile);
  };

  const uploadData = async (e) => {
    // if (!excelData || !excelData["Phone number"]) {
    //   toast.error("Please provide phone number in the excel file.");
    //   return;
    // }

    const newData = {};
  

    excelData.forEach((rowData) => {
      const contactNumber = rowData["Phone number"];

      const payload = {
        name: rowData["Name"] || "na",
        email: rowData["Email id"] || "na",
        mobile_no: rowData["Phone number"] || "na",
        address: rowData["Residence address (City)"] || "na",
        dob: rowData["DOB(DD MMM)"] || "na",
        dom: rowData["Date of Marriage (DD/MM)"] || "na",
        bloodGroup: rowData["Blood Group"] || "na",
        education: rowData["Education"] || "na",
        leaving:"na",
        schoolHouse:"na",
        profession: rowData["Full Nature of Profession"] || "na",
        jobProfile: rowData["profile"] || "na",
        companyName: rowData["company name & address "] || "na",
        ISDCode: rowData["ISD code"] || "na",
        status: true,
        schoolImg:"NA"
       // Use null if base64Image is not available
    };
      newData[contactNumber] = payload;

    });

    try {
      await update(ref(database, "ExcelData"), newData);
      navigate("/registered");
      toast.success("Excel Data uploaded successfully");
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
    }
  };

  const formatDate = (dateString, inputFormat, outputFormat) => {
    const date = moment(dateString, inputFormat);
    return date.isValid() ? date.format(outputFormat) : dateString;
  };

  useEffect(() => {
    if (dataTableRef.current) {
      $(dataTableRef.current).DataTable();
    }
  }, [excelData]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  return (
    <div>
      {window.innerWidth >= 768 && <Navbar />}
      <Sidebar />
      <Chatbot />
      <div className="admin__section">
        <h2 className="admin_heading">Upload Multiple Data</h2>
      <div className="main__container">
        <div className="card__upload ">
          <label className="upload_folder">
            Upload Folder <i class="fa fa-cloud-arrow-up"></i>
            <input
              type="file"
            //   directory=""
            //   webkitdirectory=""
              accept=".xlsx"
              onChange={handleFileChange}
              className="input__field data__input "
            />
          </label>

          <button className="button__btn" onClick={uploadData}>
            Upload
          </button>
        </div>
      </div>
      <Footer />
      </div>
    </div>
  );
};
export default MultiUserDataUpload;
