import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import $ from "jquery";
import "datatables.net";
import "../assets/Tables.css";
import "../assets/AdminDashboard.css";
import { onValue, ref, update } from "firebase/database";
import { auth, database } from "../Firebase";
import { toast } from "react-toastify";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Chatbot from "../shared/Chatbot";
import Footer from "../shared/Footer";

const StudentRequest = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const dataTableRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (users.length > 0 && dataTableRef.current) {
      $(dataTableRef.current).DataTable();
    }
  }, [users]);

  const userId = localStorage.getItem("uid");

  useEffect(() => {
    const userRef = ref(database, `Users`);
    onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const userData = snapshot.val();
        const Data = Object.values(userData);
        const filteredData = Data.filter(
          (item) => item.id !== userId && item.status == false
        );
        const selfData = Data.filter((item) => item.id === userId);
        const batch = selfData[0].batch;
        const filterByBatch = Data.filter(
          (item) =>
            item.id !== userId && item.status == false && item.batch == batch
        );
        // Super Admin and One New Admin can see all batch student request
        if (
          userId === "ur5D8WdyryUoYLXyu86sHX1hbeJ2" ||
          "uvwhpu6R7HcT8xaWaoGyqxuFHgq1"
        ) {
          setUsers(filteredData);
        }
        // Normal Admin
        else {
          setUsers(filterByBatch);
        }
        setIsLoading(false); // Set loading state to false once data is fetched
      }
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleVerify = async (userId) => {
    let payload = {
      status: true,
    };
    try {
      await update(ref(database, `Users/${userId}`), payload);
      toast.success("Verified Successfully!!");
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
    }
  };

  const handleReject = async (userId) => {
    let payload = {
      status: "Rejected",
    };
    try {
      await update(ref(database, `Users/${userId}`), payload);
      toast.success("Rejected Successfully!!");
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#my-table" });
    doc.save("table.pdf");
  };

  const generateXML = () => {
    const xmlHeader = '<?xml version="1.0" encoding="UTF-8"?>';
    let xmlContent = "<table>";

    users.forEach((user) => {
      xmlContent += `
        <row>
          <name>${user.name}</name>
          <email>${user.email}</email>
          <dob>${user.dob}</dob>
          <mobile>${user.mobile_no}</mobile>
          <house>${user.schoolHouse}</house>
          <leaving>${user.leaving}</leaving>
          <profession>${user.profession}</profession>
          <jobProfile>${user.jobProfile}</jobProfile>
        </row>
      `;
    });

    xmlContent += "</table>";

    const xmlData = xmlHeader + xmlContent;
    const xmlBlob = new Blob([xmlData], { type: "text/xml" });
    const xmlUrl = URL.createObjectURL(xmlBlob);
    const link = document.createElement("a");
    link.href = xmlUrl;
    link.download = "table.xml";
    link.click();
  };

  return (
    <div>
      {window.innerWidth >= 768 && <Navbar />}
      <Sidebar />
      <Chatbot />
      <div className="admin__section">
        <h2 className="admin_heading">Unregistered Students</h2>
        <div className="main__container">
          {isLoading ? (
            <div className="loader"></div>
          ) : users && users.length > 0 ? (
            <div className="table-container table-responsive">
              <h4 className="admin__req">Students Request</h4>
              <table
                className="display"
                id="my-table"
                style={{ width: "100%" }}
                ref={dataTableRef}
              >
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>Student Name</th>
                    <th style={{ textAlign: "center" }}>Student Email</th>
                    <th style={{ textAlign: "center" }}>Student DOB</th>
                    <th style={{ textAlign: "center" }}>Mobile Number</th>
                    <th style={{ textAlign: "center" }}>School House</th>
                    <th style={{ textAlign: "center" }}>School Leaving Year</th>
                    <th style={{ textAlign: "center" }}>Profession</th>
                    <th style={{ textAlign: "center" }}>Job Profile</th>
                    <th style={{ textAlign: "center" }}>Accept</th>
                    <th style={{ textAlign: "center" }}>Reject</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user.id}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.dob || "N/A"}</td>
                      <td>{user.mobile_no}</td>
                      <td>{user.schoolHouse}</td>
                      <td>{user.leaving}</td>
                      <td>{user.profession}</td>
                      <td>{user.jobProfile}</td>
                      <td>
                        <button
                          onClick={() => handleVerify(user.id)}
                          className="verify__btn"
                        >
                          Accept <i class="fa-solid fa-check"></i>
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => handleReject(user.id)}
                          className="decline__btn"
                        >
                          Reject <i class="fa-solid fa-xmark"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="donwload__buttons">
                <button className="button__download" onClick={generatePDF}>
                  <i class="fa fa-download" aria-hidden="true"></i>Download as
                  PDF
                </button>
                <button className="button__download" onClick={generateXML}>
                  <i class="fa fa-download" aria-hidden="true"></i>Download as
                  XML
                </button>
              </div>
            </div>
          ) : (
            <div className="no-data">No data available</div> // Render "No data" message if there are no users
          )}
        </div>
        {users && users.length > 0 ? (
          <Footer />
        ) : null}
      </div>
    </div>
  );
};

export default StudentRequest;
