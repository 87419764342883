import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "../assets/DataUpload.css";
import { ref, update } from "firebase/database";
import { database } from "../Firebase";
import $ from "jquery";
import "datatables.net";
import "../assets/Tables.css";
import Chatbot from "../shared/Chatbot";
import Footer from "../shared/Footer";
const ExcelToJsonAndImageToBase64 = () => {
  const [excelData, setExcelData] = useState(null);
  const [Data, setData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [base64Image, setBase64Image] = useState(null);
  const navigate = useNavigate();
  const dataTableRef = useRef(null);
  const sanitizeKey = (key) => {
    return key.replace(/[.#$/[\]]/g, "_");
  };
  // const handleFileChange = async (event) => {
  //   var excelFile, imageFile;
  //   if (event.target.files[0].name.includes(".xlsx")) {
  //     excelFile = event.target.files[0];
  //     imageFile = event.target.files[1];
  //   }
  //   if (
  //     event.target.files.length === 2 &&
  //     event.target.files[1].name.includes(".xlsx")
  //   ) {
  //     excelFile = event.target.files[1];
  //     imageFile = event.target.files[0];
  //   }
  //   if (
  //     event.target.files.length === 1 &&
  //     !event.target.files[0].name.includes(".xlsx")
  //   ) {
  //     toast.error("No excel found");
  //     return;
  //   }

  //   const reader = new FileReader();
  //   // Read Excel file
  //   reader.onload = (e) => {
  //     const data = new Uint8Array(e.target.result);
  //     const workbook = XLSX.read(data, { type: "array" });
  //     const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //     const jsonData = XLSX.utils.sheet_to_json(worksheet);
  //     const excelDataWithImage = {
  //       ...jsonData[0],
  //       base64Image: base64Image,
  //     };
  //     setExcelData(excelDataWithImage);
  //   };
  //   reader.readAsArrayBuffer(excelFile);

  //   if (imageFile) {
  //     // Convert image to base64
  //     const imageReader = new FileReader();
  //     imageReader.onload = (e) => {
  //       const base64 = imageReader.result;

  //       setBase64Image(base64);
  //     };
  //     imageReader.readAsDataURL(imageFile);
  //   }
  // };

  const handleFileChange = async (event) => {
    var excelFile, imageFile;
    if (event.target.files[0].name.includes(".xlsx")) {
      excelFile = event.target.files[0];
      imageFile = event.target.files[1];
    }
    if (
      event.target.files.length === 2 &&
      event.target.files[1].name.includes(".xlsx")
    ) {
      excelFile = event.target.files[1];
      imageFile = event.target.files[0];
    }
    if (
      event.target.files.length === 1 &&
      !event.target.files[0].name.includes(".xlsx")
    ) {
      toast.error("No excel found");
      return;
    }

    const reader = new FileReader();
    // Read Excel file
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      // Convert the date to YYYY-MM-DD format
      const excelDataWithFormattedDate = {
        ...jsonData[0],
        base64Image: base64Image,
        "Date of Birth": formatDate(jsonData[0]["Date of Birth"]),
        "Date of Marriage": formatDate(jsonData[0]["Date of Marriage"]),
      };

      setExcelData(excelDataWithFormattedDate);
    };
    reader.readAsArrayBuffer(excelFile);

    if (imageFile) {
      // Convert image to base64
      const imageReader = new FileReader();
      imageReader.onload = (e) => {
        const base64 = imageReader.result;
        setBase64Image(base64);
      };
      imageReader.readAsDataURL(imageFile);
    }
  };

  // Function to format the date to DD-MM-YYYY format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());
    return `${day}-${month}-${year}`;
  };

  const uploadData = async (e) => {
    if (!excelData || !excelData["Contact Number"]) {
      toast.error("Please provide contact number in the excel file.");
      return;
    }

    const payload = {
      [excelData["Contact Number"]]: {
        name: excelData["Name"] || "na",
        email: excelData["Email ID"] || "na",
        mobile_no: excelData["Contact Number"] || "na",
        address: excelData["Residential Address"] || "na",
        dob: excelData["Date of Birth"] || "na",
        dom: excelData["Date of Marriage"] || "na",
        bloodGroup: excelData["Blood Group"] || "na",
        schoolHouse: excelData["School House"] || "na",
        lastClass: excelData["Last Class Attended"] || "na",
        leaving: excelData["Year leaving school"] || "na",
        education: excelData["Education"] || "na",
        profession: excelData["Nature of Profession/Business/Job"] || "na",
        jobProfile: excelData["Profile"] || "na",
        companyName: excelData["Company's Name & Address"] || "na",
        schoolImg: base64Image || "NA",
      }, // Use null if base64Image is not available
    };

    const updatedPayload = {
      [excelData["Contact Number"]]: {},
    };

    // Include all columns from excelData dynamically
    Object.keys(excelData).forEach((key) => {
      const value = excelData[key];
      const sanitizedKey = sanitizeKey(key);

      if (
        !payload[excelData["Contact Number"]].hasOwnProperty(sanitizedKey) &&
        !updatedPayload[excelData["Contact Number"]].hasOwnProperty(
          sanitizedKey
        )
      ) {
        if (
          !Object.values(payload[excelData["Contact Number"]]).includes(
            value
          ) &&
          !Object.values(updatedPayload[excelData["Contact Number"]]).includes(
            value
          )
        ) {
          updatedPayload[excelData["Contact Number"]][sanitizedKey] = value;
        }
      }
    });

    const mergedData = {
      ...payload[excelData["Contact Number"]],
      ...updatedPayload[excelData["Contact Number"]],
    };

    for (let key in mergedData) {
      if (mergedData.hasOwnProperty(key)) {
        mergedData[key] = String(mergedData[key]);
      }
    }

    var data = { [excelData["Contact Number"]]: mergedData };

    // Rest of your code...

    try {
      await update(ref(database, `ExcelData`), data);
      navigate("/registered");
      toast.success("Excel Data uploaded successfully");
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
    }
  };

  const handleTableSubmit = (e) => {
    navigate("/unregister");
  };

  useEffect(() => {
    if (dataTableRef.current) {
      $(dataTableRef.current).DataTable();
    }
  }, [excelData]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {window.innerWidth >= 768 && <Navbar />}
      <Sidebar />
      <Chatbot />
      <div className="admin__section">
        <h2 className="admin_heading">Upload Single Data</h2>
      <div className="main__container">
        <div className="card__upload ">
          <label className="upload_folder">
            Upload Folder <i class="fa fa-cloud-arrow-up"></i>
            <input
              type="file"
              directory=""
              webkitdirectory=""
              accept=".xlsx"
              onChange={handleFileChange}
              className="input__field data__input "
            />
          </label>

          <button className="button__btn" onClick={uploadData}>
            Upload
          </button>
        </div>
      </div>
      <Footer />
      </div>
    </div>
  );
};
export default ExcelToJsonAndImageToBase64;
