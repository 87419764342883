import React, { useEffect, useState } from "react";
import "../assets/Profile.css";
import Navbar from "../shared/dNavbar";
import { auth, database } from "../Firebase";
import { onValue, ref, set, update } from "firebase/database";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../shared/Footer";
import Chatbot from "../shared/Chatbot";

const Profile = () => {
  const [user, setUser] = useState("");
  const userId = localStorage.getItem("uid");
  const [checkedDom, setCheckedDom] = useState(false);
  const [checkedAddress, setCheckedAddress] = useState(false);
  const [checkedAddress2, setCheckedAddress2] = useState(false);
  const [checkedAddress3, setCheckedAddress3] = useState(false);
  const [checkedCountry, setCheckedCountry] = useState(false);
  const [checkedPincode, setCheckedPincode] = useState(false);
  const [checkedCompanyAddress, setCheckedCompanyAddress] = useState(false);
  const [checkedCompanyAddress2, setCheckedCompanyAddress2] = useState(false);
  const [checkedCompanyAddress3, setCheckedCompanyAddress3] = useState(false);
  const [checkedCompanyCity, setCheckedCompanyCity] = useState(false);
  const [checkedCompanyCountry, setCheckedCompanyCountry] = useState(false);
  const [checkedCompanyPincode, setCheckedCompanyPincode] = useState(false);
  const [checkedCurrentPhoto, setCheckedCurrentPhoto] = useState(false);
  const [checkedSchoolPhoto, setCheckedSchoolPhoto] = useState(false);
  const [visibility, setVisibility] = useState(true);
  const [privateData, setPrivateData] = useState();

  const [jsonData, setJsonData] = useState("");

  const navigate = useNavigate();

  const handlePrivacy = async (key) => {
    const updatedObject = { ...jsonData };

    if (key in updatedObject.private) {
      updatedObject[key] = updatedObject.private[key];
      delete updatedObject.private[key];
    } else {
      updatedObject.private[key] = updatedObject[key];
      delete updatedObject[key];
    }

    await setJsonData(updatedObject);
  };

  console.log("This is visibility", visibility);

  const handleChangeDom = (val, key) => {
    setCheckedDom(val);
    handlePrivacy(key);
    localStorage.setItem("switchDomState", JSON.stringify(val));
  };
  const handleChangeAddress = (val, key) => {
    setCheckedAddress(val);
    handlePrivacy(key);
    localStorage.setItem("switchAdrState", JSON.stringify(val));
  };

  const handleChangeAddress2 = (val, key) => {
    setCheckedAddress2(val);
    handlePrivacy(key);
    localStorage.setItem("switchAdr2State", JSON.stringify(val));
  };

  const handleChangeAddress3 = (val, key) => {
    setCheckedAddress3(val);
    handlePrivacy(key);
    localStorage.setItem("switchAdr3State", JSON.stringify(val));
  };

  const handleChangeCountry = (val, key) => {
    setCheckedCountry(val);
    handlePrivacy(key);
    localStorage.setItem("switchCountry", JSON.stringify(val));
  };

  const handleChangePincode = (val, key) => {
    setCheckedPincode(val);
    handlePrivacy(key);
    localStorage.setItem("switchPincode", JSON.stringify(val));
  };

  const handleChangeCAddress = (val, key) => {
    setCheckedCompanyAddress(val);
    handlePrivacy(key);
    localStorage.setItem("switchCAdrState", JSON.stringify(val));
  };
  const handleChangeCAddress2 = (val, key) => {
    setCheckedCompanyAddress2(val);
    handlePrivacy(key);
    localStorage.setItem("switchCAdr2State", JSON.stringify(val));
  };
  const handleChangeCAddress3 = (val, key) => {
    setCheckedCompanyAddress3(val);
    handlePrivacy(key);
    localStorage.setItem("switchCAdr3State", JSON.stringify(val));
  };
  const handleChangeCCity = (val, key) => {
    setCheckedCompanyCity(val);
    handlePrivacy(key);
    localStorage.setItem("switchCCityState", JSON.stringify(val));
  };
  const handleChangeCCountry = (val, key) => {
    setCheckedCompanyCountry(val);
    handlePrivacy(key);
    localStorage.setItem("switchCCountryState", JSON.stringify(val));
  };
  const handleChangeCPin = (val, key) => {
    setCheckedCompanyPincode(val);
    handlePrivacy(key);
    localStorage.setItem("switchCPinState", JSON.stringify(val));
  };
  const handleChangeCurentPhoto = (val, key) => {
    setCheckedCurrentPhoto(val);
    handlePrivacy(key);
    localStorage.setItem("switchCurrentphoto", JSON.stringify(val));
  };
  const handleChangeSchoolPhoto = (val, key) => {
    setCheckedSchoolPhoto(val);
    handlePrivacy(key);
    localStorage.setItem("switchSchoolphoto", JSON.stringify(val));
  };

  const handlePrivacySubmit = async () => {
    let payload = jsonData;

    try {
      await set(ref(database, `Users/${userId}`), payload);
      toast.success("Privacy Setting Updated Successfully!!");
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
    }
  };

  const handleSubmit = (id) => {
    navigate(`/edit`, { state: { users: user } });
  };

  // const handleDelete = async (userId) => {
  //   let payload = {
  //     status: "Deleted",
  //   };
  //   try {
  //     await update(ref(database, `Users/${userId}`), payload);
  //     toast.success("Deleted Successfully!!");
  //   } catch (error) {
  //     console.error("Error:", error);
  //     toast.error(error);
  //   }
  // };

  useEffect(() => {
    // Retrieve the state from local storage
    const Dom = localStorage.getItem("switchDomState");
    const Adr = localStorage.getItem("switchAdrState");
    const Adr2 = localStorage.getItem("switchAdr2State");
    const Adr3 = localStorage.getItem("switchAdr3State");
    const Country = localStorage.getItem("switchCountry");
    const Pin = localStorage.getItem("switchPincode");
    const CAddr = localStorage.getItem("switchCAdrState");
    const CAddr2 = localStorage.getItem("switchCAdr2State");
    const CAddr3 = localStorage.getItem("switchCAdr3State");
    const CCity = localStorage.getItem("switchCCityState");
    const CCountry = localStorage.getItem("switchCCountryState");
    const CPin = localStorage.getItem("switchCPinState");
    const CurrentPhoto = localStorage.getItem("switchCurrentphoto");
    const SchoolPhoto = localStorage.getItem("switchSchoolphoto");

    if (Dom) {
      setCheckedDom(JSON.parse(Dom));
    } else if (Adr) {
      setCheckedAddress(JSON.parse(Adr));
    } else if (Adr2) {
      setCheckedAddress2(JSON.parse(Adr));
    } else if (Adr3) {
      setCheckedAddress3(JSON.parse(Adr));
    } else if (Country) {
      setCheckedCountry(JSON.parse(Adr));
    } else if (Pin) {
      setCheckedPincode(JSON.parse(Adr));
    } else if (CAddr) {
      setCheckedCompanyAddress(JSON.parse(CAddr));
    } else if (CAddr2) {
      setCheckedCompanyAddress2(JSON.parse(CAddr2));
    } else if (CAddr3) {
      setCheckedCompanyAddress3(JSON.parse(CAddr3));
    } else if (CCity) {
      setCheckedCompanyCity(JSON.parse(CCity));
    } else if (CCountry) {
      setCheckedCompanyCountry(JSON.parse(CCountry));
    } else if (CPin) {
      setCheckedCompanyPincode(JSON.parse(CPin));
    } else if (CurrentPhoto) {
      setCheckedCurrentPhoto(JSON.parse(CurrentPhoto));
    } else if (SchoolPhoto) {
      setCheckedSchoolPhoto(JSON.parse(SchoolPhoto));
    }
  }, []);

  useEffect(() => {
    const userRef = ref(database, `Users/${userId}`);
    onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const userData = snapshot.val();
        setUser(userData);
        setJsonData(userData);

        if (userData.private) {
          setCheckedDom("dom" in userData.private);
          setCheckedAddress("address" in userData.private);
          setCheckedAddress2("address2" in userData.private);
          setCheckedAddress3("address3" in userData.private);
          setCheckedCountry("country" in userData.private);
          setCheckedPincode("pincode" in userData.private);
          setCheckedCompanyAddress("companyAddress" in userData.private);
          setCheckedCompanyAddress2("companyAddress2" in userData.private);
          setCheckedCompanyAddress3("companyAddress3" in userData.private);
          setCheckedCompanyCity("companyCity" in userData.private);
          setCheckedCompanyCountry("companyCountry" in userData.private);
          setCheckedCompanyPincode("companyPincode" in userData.private);
          setCheckedCurrentPhoto("img" in userData.private);
          setCheckedSchoolPhoto("schoolImg" in userData.private);
          // setVisibility("searching" in userData.private);
        }
        setPrivateData(userData.private);
      }
    });
  }, []);

  console.log(jsonData);

  return (
    <div className="view__container">
      <Navbar />
      <Chatbot />
      <div className="user_profile_container">
        <div className="profile__card">
          <div className="images__container">
            <div className="img__container">
              <img
                src={user.img ? user.img : privateData && privateData.img}
                alt=""
                className="user__profile"
              />
              <h5>Current Photo</h5>
              <div className="app" style={{ textAlign: "center" }}>
                <ReactSwitch
                  checked={checkedCurrentPhoto}
                  onChange={(val) => handleChangeCurentPhoto(val, "img")}
                />
              </div>
            </div>
            <div className="img__container">
              <img
                src={
                  user.schoolImg
                    ? user.schoolImg
                    : privateData && privateData.schoolImg
                }
                alt=""
                className="user__profile"
              />
              <h5>School Photo</h5>
              <div className="app" style={{ textAlign: "center" }}>
                <ReactSwitch
                  checked={checkedSchoolPhoto}
                  onChange={(val) => handleChangeSchoolPhoto(val, "schoolImg")}
                />
              </div>
            </div>
          </div>
          <div className="details__container">
            <div className="input__details">
              <label className="input__label">Name</label>
              <input type="text" value={user.name} className="input__field" />
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Email</label>
              <input type="text" value={user.email} className="input__field" />
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Contact</label>
              <input
                type="text"
                value={user.mobile_no}
                className="input__field"
              />
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Blood Group</label>
              <input
                type="text"
                value={user.bloodGroup}
                className="input__field"
              />
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Date of Birth</label>
              <input type="text" value={user.dob} className="input__field" />
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Profession</label>
              <input
                type="text"
                value={user.profession}
                className="input__field"
              />
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Job Profile</label>
              <input
                type="text"
                value={user.jobProfile}
                className="input__field"
              />
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">City</label>
              <input type="text" value={user.city} className="input__field" />
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">School House</label>
              <input
                type="text"
                value={user.schoolHouse}
                className="input__field"
              />
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Last Class Attended</label>
              <input
                type="text"
                value={user.lastClass}
                className="input__field"
              />
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Section</label>
              <input
                type="text"
                value={user.section}
                className="input__field"
              />
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">School Leaving Year</label>
              <input
                type="text"
                value={user.leaving}
                className="input__field"
              />
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Education</label>
              <input
                type="text"
                value={user.education}
                className="input__field"
              />
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Company Name</label>
              <input
                type="text"
                value={user.companyName}
                className="input__field"
              />
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Company City</label>
              <input
                type="text"
                value={user.companyCity}
                className="input__field"
              />
            </div>
            <hr />

            {/* Private Fields */}
            <p>
              * active toggle means that field is private and not seen by other
              users{" "}
            </p>

            <div className="input__details">
              <label className="input__label">Date of Marriage</label>
              <input
                type="text"
                value={user.dom ? user.dom : privateData && privateData.dom}
                className="input__field"
              />
              <div className="app" style={{ textAlign: "center" }}>
                <ReactSwitch
                  checked={checkedDom}
                  onChange={(val) => handleChangeDom(val, "dom")}
                />
              </div>
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Address Line-1</label>
              <input
                type="text"
                value={
                  user.address
                    ? user.address
                    : privateData && privateData.address
                }
                className="input__field"
              />
              <div className="app" style={{ textAlign: "center" }}>
                <ReactSwitch
                  checked={checkedAddress}
                  onChange={(val) => handleChangeAddress(val, "address")}
                />
              </div>
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Address Line-2</label>
              <input
                type="text"
                value={
                  user.address2
                    ? user.address2
                    : privateData && privateData.address2
                }
                className="input__field"
              />
              <div className="app" style={{ textAlign: "center" }}>
                <ReactSwitch
                  checked={checkedAddress2}
                  onChange={(val) => handleChangeAddress2(val, "address2")}
                />
              </div>
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Address Line-3</label>
              <input
                type="text"
                value={
                  user.address3
                    ? user.address3
                    : privateData && privateData.address3
                }
                className="input__field"
              />
              <div className="app" style={{ textAlign: "center" }}>
                <ReactSwitch
                  checked={checkedAddress3}
                  onChange={(val) => handleChangeAddress3(val, "address3")}
                />
              </div>
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Country</label>
              <input
                type="text"
                value={
                  user.country
                    ? user.country
                    : privateData && privateData.country
                }
                className="input__field"
              />
              <div className="app" style={{ textAlign: "center" }}>
                <ReactSwitch
                  checked={checkedCountry}
                  onChange={(val) => handleChangeCountry(val, "country")}
                />
              </div>
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Pincode</label>
              <input
                type="text"
                value={
                  user.pincode
                    ? user.pincode
                    : privateData && privateData.pincode
                }
                className="input__field"
              />
              <div className="app" style={{ textAlign: "center" }}>
                <ReactSwitch
                  checked={checkedPincode}
                  onChange={(val) => handleChangePincode(val, "pincode")}
                />
              </div>
            </div>

            <hr />
            <div className="input__details">
              <label className="input__label">Company Address Line-1</label>
              <input
                type="text"
                value={
                  user.companyAddress
                    ? user.companyAddress
                    : privateData && privateData.companyAddress
                }
                className="input__field"
              />
              <div className="app" style={{ textAlign: "center" }}>
                <ReactSwitch
                  checked={checkedCompanyAddress}
                  onChange={(val) =>
                    handleChangeCAddress(val, "companyAddress")
                  }
                />
              </div>
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Company Address Line-2</label>
              <input
                type="text"
                value={
                  user.companyAddress2
                    ? user.companyAddress2
                    : privateData && privateData.companyAddress2
                }
                className="input__field"
              />
              <div className="app" style={{ textAlign: "center" }}>
                <ReactSwitch
                  checked={checkedCompanyAddress2}
                  onChange={(val) =>
                    handleChangeCAddress2(val, "companyAddress2")
                  }
                />
              </div>
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Company Address Line-3</label>
              <input
                type="text"
                value={
                  user.companyAddress3
                    ? user.companyAddress3
                    : privateData && privateData.companyAddress3
                }
                className="input__field"
              />
              <div className="app" style={{ textAlign: "center" }}>
                <ReactSwitch
                  checked={checkedCompanyAddress3}
                  onChange={(val) =>
                    handleChangeCAddress3(val, "companyAddress3")
                  }
                />
              </div>
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Company City</label>
              <input
                type="text"
                value={
                  user.companyCity
                    ? user.companyCity
                    : privateData && privateData.companyCity
                }
                className="input__field"
              />
              <div className="app" style={{ textAlign: "center" }}>
                <ReactSwitch
                  checked={checkedCompanyCity}
                  onChange={(val) => handleChangeCCity(val, "companyCity")}
                />
              </div>
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Company Country</label>
              <input
                type="text"
                value={
                  user.companyCountry
                    ? user.companyCountry
                    : privateData && privateData.companyCountry
                }
                className="input__field"
              />
              <div className="app" style={{ textAlign: "center" }}>
                <ReactSwitch
                  checked={checkedCompanyCountry}
                  onChange={(val) =>
                    handleChangeCCountry(val, "companyCountry")
                  }
                />
              </div>
            </div>
            <hr />
            <div className="input__details">
              <label className="input__label">Company Pincode</label>
              <input
                type="text"
                value={
                  user.companyPincode
                    ? user.companyPincode
                    : privateData && privateData.companyPincode
                }
                className="input__field"
              />
              <div className="app" style={{ textAlign: "center" }}>
                <ReactSwitch
                  checked={checkedCompanyPincode}
                  onChange={(val) => handleChangeCPin(val, "companyPincode")}
                />
              </div>
            </div>
            <hr />

            <div className="radio_input-details">
              <label
                className="radio__label"
                data-tooltip="Allow other batches to see your profile"
              >
                Visibility to other Batches :
              </label>
              <div className="radio_inputs">
                <div>
                  <input
                    type="radio"
                    checked={visibility}
                    onChange={() => setVisibility(true)}
                  />
                  Yes
                </div>
                <div>
                  <input
                    type="radio"
                    checked={!visibility}
                    onChange={() => setVisibility(false)}
                  />
                  No
                </div>
              </div>
            </div>
            <hr />

            <button onClick={handlePrivacySubmit} className="button__btn">
              Privacy Settings Update
            </button>
            <button
              onClick={() => handleSubmit(user.id)}
              className="button__btn"
            >
              Edit Details
            </button>
            {/* <Link onClick={()=>handleDelete(user.id)} className="button__btn">
              Delete User
            </Link> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
