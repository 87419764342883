import React, { useEffect, useState } from "react";
import Navbar from "../shared/dNavbar";
import { useLocation } from "react-router-dom";
import Chatbot from "../shared/Chatbot";
import Footer from "../shared/Footer";

const BatchMateData = () => {
  const [user, setUser] = useState();
  const location = useLocation();
  const users = location?.state?.users || "N/A";
  const userData = JSON.stringify(users[0]);

  useEffect(() => {
    // Connvert into key value pair
    const userParse = JSON.parse(userData);
    setUser(userParse);
  }, []);

 

  return (
    <div>
      <Navbar />
      <Chatbot />
      {user && (
        <div className="view__container">
        <div className="user_profile_container">
          <div className="profile__card">
            <div className="img__container">
              <img src={user.img} alt="" className="user__profile" />
            </div>
            <div className="details_container">
              <h4 className="detial_tab">
                Name : {user.name || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">Email : {user.email}</h4>
              <hr />
              <h4 className="detial_tab">Mobile No. :{user.mobile_no}</h4>
              <hr />
              <h4 className="detial_tab">
                Dirth of Birth : {user.dob || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Date of Marriage : {user.dom || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Blood Group : {user.bloodGroup || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Address Line-1 : {user.address || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Address Line-2 : {user.address1 || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Address Line-3 : {user.address2 || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                City : {user.city || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Country : {user.country || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Pincode : {user.pincode || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Education : {user.education || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                School House : {user.schoolHouse || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Leaving Year : {user.leaving || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Section : {user.section || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Last Class Attended : {user.lastClass || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Profession : {user.profession || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Job Profile : {user.jobProfile || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Company Name : {user.companyName || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Company Address Line-1 :{" "}
                {user.companyAddress || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Company Address Line-2 :{" "}
                {user.companyAddress2 || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Company Address Line-3 :{" "}
                {user.companyAddress3 || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Company City : {user.companyCity || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Company Country : {user.companyCountry || "This is Private"}
              </h4>
              <hr />
              <h4 className="detial_tab">
                Company Pincode : {user.companyPinocde || "This is Private"}
              </h4>
              <hr />
            </div>
          </div>
        </div>
        <Footer />
        </div>
      )}
    </div>
  );
};

export default BatchMateData;
