import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../assets/AdminSidebar.css";
import { auth } from "../Firebase";
import { toast } from "react-toastify";

const Sidebar = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  const userId = localStorage.getItem("uid");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize(); // Check initial width on component mount

    window.addEventListener("resize", handleResize); // Listen for window resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup the event listener on component unmount
    };
  }, []);

  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        navigate("/", { replace: true });
        window.location.reload();
        localStorage.removeItem("user");
        localStorage.removeItem("uid");
        toast.success("Logout Successfully");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  if (isMobile) {
    return (
      <nav className="navbar">
        <img
          className="img__logo"
          src="https://mpskalwarroad.com/assets/images/mps_kalwarroad.png"
          alt=""
        />

        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link className="sidebar__link1" to="/admin">
              <li>Dashboard</li>
            </Link>
          </li>
          {userId === "ur5D8WdyryUoYLXyu86sHX1hbeJ2" && (
              <li className="nav-item">
                <Link className="sidebar__link1" to="/adminList">
                  <li>Admin List</li>
                </Link>
              </li>
            )}
          <li className="nav-item">
            <Link className="sidebar__link1" to="/students">
              <li>Verified Students</li>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="sidebar__link1" to="/rejected">
              <li>Rejected Students</li>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="sidebar__link1" to="/request">
              <li>Registered Students</li>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="sidebar__link1" to="/unregister">
              <li>Unregistered Students</li>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/upload" className="sidebar__link1">
              <li>Upload Single Data</li>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/uploadExcel" className="sidebar__link1">
              <li>Upload Multiple Data</li>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/" onClick={handleLogout} className="sidebar__link1">
              <li>Logout</li>
            </Link>
          </li>
        </ul>
        <div className="nav-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </nav>
    );
  }

  return (
    <div className="admin__sidebar">
      <ul className="sidebar__items">
        <Link className="sidebar__link" to="/admin">
          <div className="sidebar__link-content">
            <i className="fa-solid fa-house"></i>
            <p>Dashboard</p>
          </div>
        </Link>
        {userId === "ur5D8WdyryUoYLXyu86sHX1hbeJ2" && (
            <Link className="sidebar__link" to="/adminList">
              <div className="sidebar__link-content">
                <i className="fa-solid fa-users"></i>
                <p>Admin List</p>
              </div>
            </Link>
          )}

        <Link className="sidebar__link" to="/students">
          <div className="sidebar__link-content">
            <i className="fa-solid fa-check"></i> <p>Verified Students</p>
          </div>
        </Link>
        <Link className="sidebar__link" to="/rejected">
          <div className="sidebar__link-content">
            <i className="fa-solid fa-xmark"></i>
            <p> Rejected Students</p>
          </div>
        </Link>
        <Link className="sidebar__link" to="/request">
          <div className="sidebar__link-content">
            <i class="fa-solid fa-user-plus"></i>
            <p> Unverfied Students</p>
          </div>
        </Link>
        <Link className="sidebar__link" to="/registered">
          <div className="sidebar__link-content">
            <i class="fa-solid fa-user-minus"></i>
            <p> Registered Students</p>
          </div>
        </Link>
        <Link to="/upload" className="sidebar__link">
          <div className="sidebar__link-content">
            <i className="fa-solid fa-upload"></i>
            <p> Upload Single Data</p>
          </div>
        </Link>
        <Link to="/uploadExcel" className="sidebar__link">
          <div className="sidebar__link-content">
            <i className="fa-solid fa-upload"></i>
            <p> Upload Multiple Data</p>
          </div>
        </Link>
        <Link to="/" onClick={handleLogout} className="sidebar__link">
          <div className="sidebar__link-content">
            <i className="fa-solid fa-right-from-bracket"></i>
            <p>Logout</p>
          </div>
        </Link>
      </ul>
    </div>
  );
};

export default Sidebar;
